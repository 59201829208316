:root {
  --font-family-sans: -apple-system, blinkmacsystemfont, "Segoe UI", roboto, "Noto Sans", oxygen, ubuntu, cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  --font-size-base: 1rem;
  --font-size-sm: 0.875rem;
  --font-size-xs: 0.75rem;
  --font-size-monospace: 0.95em;
  --line-height-base: 1.5;
  --line-height-heading: 1.2;
  --white: #fff;
  --very-light-grey: #f8f8f8;
  --light-grey: #f2f2f2;
  --medium-grey: #9ba7af;
  --dark-grey: #4d545d;
  --black: #333;
  --primary: #024cb6;
  --secondary: #4d545d;
  --success: #138347;
  --danger: #cc0003;
  --warning: #ea6b19;
  --focus: #3fb3f7;
  --focus-btn-primary: rgba(11, 106, 162, 0.5);
  --focus-btn-secondary: rgba(11, 106, 162, 0.5);
  --focus-btn-danger: rgba(204, 0, 3, 0.5);
  --focus-input-border: #063F61;
  --focus-input-glow: rgba(11, 106, 162, 0.25);
  --primary-hover: #0587d4;
  --primary-active: #095683;
  --danger-hover: #eb383b;
  --danger-active: #b50003;
  --weather-icon-color: var(--primary);
  --unstable-build-icon-color: var(--notification-warning-icon-color);
  --background: var(--white);
  --line-green: #acb;
  --medium-translucent: rgba(255, 255, 255, 0.75);
  --logo-bg: var(--white);
  --brand-link-color: var(--secondary);
  --header-link-color: var(--white);
  --header-link-color-active: #f5f5f5;
  --header-link-outline: var(--focus);
  --header-search-border: var(--white);
  --search-input-color: var(--brand-link-color);
  --search-bg: var(--white);
  --search-box-completion-bg: var(--primary-hover);
  --search-box-shadow: 0 1px 7px 0 rgba(0, 0, 0, 0.3);
  --header-bg-classic: #000;
  --header-link-bg-classic-hover: #404040;
  --header-link-bg-classic-active: #404040;
  --header-bg-v2: var(--primary-hover);
  --brand-link-color-hover-v2: var(--primary-hover);
  --brand-link-color-active-v2: var(--primary-active);
  --header-link-bg-hover-v2: var(--primary-hover);
  --header-link-bg-active-v2: var(--primary-active);
  --header-item-border-radius: 4px;
  --breadcrumbs-bg: #f8f8f8;
  --breadcrumbs-border: var(--light-grey);
  --breadcrumbs-text-color: #4d545d;
  --breadcrumbs-item-bg-color--hover: var(--light-grey);
  --monitor-bg-v2: #fff3cd;
  --monitor-color-v2: #856404;
  --sec-monitor-bg-v2: #f8d7da;
  --sec-monitor-color-v2: #721c24;
  --alert-default-icon-color: #2196f3;
  --alert-default-bg-color: #d1ecf1;
  --alert-default-border-color: #bee5eb;
  --alert-default-color: #0c5464;
  --notification-success-icon-color: #4caf50;
  --notification-success-bg-color: #d4edda;
  --notification-success-border-color: #c3e6cb;
  --notification-success-color: var(--success);
  --notification-warning-icon-color: #ff9800;
  --notification-warning-bg-color: #fff3cd;
  --notification-warning-border-color: #ffeeba;
  --notification-warning-color: #856404;
  --notification-error-icon-color: #f44336;
  --notification-error-bg-color: #f8d7da;
  --notification-error-border-color: #f5c6cb;
  --notification-error-color: #721c24;
  --alert-success-text-color: #155724;
  --alert-success-bg-color: #d4edda;
  --alert-success-border-color: #c3e6cb;
  --alert-info-text-color: #31708f;
  --alert-info-bg-color: #d9edf7;
  --alert-info-border-color: #bce8f1;
  --alert-warning-text-color: #8a6d3b;
  --alert-warning-bg-color: #fcf8e3;
  --alert-warning-border-color: #faebcc;
  --alert-danger-text-color: #a94442;
  --alert-danger-bg-color: #f2dede;
  --alert-danger-border-color: #ebccd1;
  --text-color: var(--black);
  --text-color-secondary: var(--secondary);
  --btn-font-weight: bold;
  --btn-text-color: var(--white);
  --btn-font-size: var(--font-size-xs);
  --btn-line-height: 1rem;
  --btn-large-font-size: var(--font-size-sm);
  --btn-large-line-height: 1.25rem;
  --btn-primary-bg: #063F61;
  --btn-primary-bg-hover: #085685;
  --btn-primary-bg-active: #0a649b;
  --btn-secondary-color: var(--secondary);
  --btn-secondary-bg: var(--btn-text-color);
  --btn-secondary-border: var(--medium-grey);
  --btn-secondary-color--hover: var(--primary);
  --btn-secondary-bg--hover: var(--btn-secondary-bg);
  --btn-secondary-border--hover: var(--primary);
  --btn-secondary-color--focus: var(--primary);
  --btn-secondary-bg--focus: var(--btn-secondary-bg);
  --btn-secondary-border--focus: var(--primary);
  --btn-secondary-color--active: var(--primary-active);
  --btn-secondary-bg--active: var(--btn-secondary-bg);
  --btn-secondary-border--active: var(--primary-active);
  --btn-link-color: var(--primary);
  --btn-link-font-weight: var(--link-font-weight);
  --btn-link-color--hover: var(--primary-hover);
  --btn-link-bg--hover: var(--very-light-grey);
  --btn-link-color--active: var(--primary-active);
  --btn-link-bg--active: var(--light-grey);
  --help-area-bg-color: var(--very-light-grey);
  --configure-job-help-area-bg-color: var(--medium-translucent);
  --table-background: var(--panel-header-bg-color);
  --table-header-foreground: black;
  --table-body-background: white;
  --table-body-foreground: black;
  --table-border-radius: 10px;
  --table-body-radius: 6px;
  --even-row-color: var(--very-light-grey);
  --bigtable-border-width: var(--pane-border-width);
  --bigtable-header-bg: var(--dark-grey);
  --bigtable-header-font-weight: bold;
  --bigtable-header-text-color: var(--white);
  --bigtable-row-border-color: var(--medium-grey);
  --bigtable-cell-padding-x: 0.75rem;
  --bigtable-cell-padding-y: 0.5rem;
  --table-parameters-bg--hover: var(--light-grey);
  --table-striped-bg--hover: var(--light-grey);
  --link-color: var(--primary);
  --link-visited-color: var(--link-color);
  --link-color--hover: var(--link-color);
  --link-color--active: var(--text-color);
  --link-text-decoration: none;
  --link-text-decoration--hover: underline;
  --link-text-decoration--active: underline;
  --link-font-weight: 600;
  --tooltip-background-color: var(--background);
  --tooltip-foreground-color: var(--text-color);
  --tooltip-shadow: 0 0 8px 2px rgba(0, 0, 0, 0.05), 0 2px 2px rgba(0, 0, 0, 0.05), 0 10px 20px rgba(0, 0, 0, 0.2);
  --link-dark-color: var(--text-color);
  --link-dark-visited-color: var(--link-dark-color);
  --link-dark-color--hover: var(--primary-hover);
  --link-dark-color--active: var(--primary-active);
  --link-dark-text-decoration: none;
  --link-dark-text-decoration--hover: underline;
  --link-dark-text-decoration--active: underline;
  --link-dark-font-weight: 600;
  --pane-border-width: 1px;
  --pane-header-text-color: var(--text-color);
  --pane-header-bg: var(--light-grey);
  --pane-header-border-color: var(--light-grey);
  --pane-header-font-weight: bold;
  --pane-border-color: var(--light-grey);
  --pane-text-color: var(--text-color);
  --pane-link-color: black;
  --pane-link-color--visited: black;
  --tabs-background: var(--panel-header-bg-color);
  --tabs-item-background: transparent;
  --tabs-item-foreground: var(--text-color);
  --tabs-item-background--hover: rgba(0, 0, 0, 0.05);
  --tabs-item-foreground--hover: var(--text-color);
  --tabs-item-background--active: rgba(0, 0, 0, 0.1);
  --tabs-item-foreground--active: var(--text-color);
  --tabs-item-background--selected: white;
  --tabs-item-foreground--selected: var(--link-color);
  --tabs-border-radius: calc((10px + 34px) / 2);
  --tab-link-border-radius: 10px;
  --tab-link-border-width: 2px;
  --tab-link-padding-x: 0.75rem;
  --tab-link-padding-y: 0.375rem;
  --tab-link-font-weight: bold;
  --tab-link-text-color: var(--text-color-secondary);
  --tab-link-bg: transparent;
  --tab-link-border-color: var(--light-grey);
  --tab-link-text-color--hover: var(--tab-link-text-color);
  --tab-link-bg--hover: var(--light-grey);
  --tab-link-border-color--hover: var(--tab-link-bg--hover);
  --tab-link-text-color--active: var(--tab-link-text-color);
  --tab-link-bg--active: var(--light-grey);
  --tab-link-border-color--active: var(--tab-link-checked-bg);
  --tab-link-checked-text-color: var(--white);
  --tab-link-checked-bg: var(--dark-grey);
  --tab-link-checked-border-color: var(--tab-link-checked-bg);
  --tab-baseline-width: 2px;
  --tab-baseline-color: var(--light-grey);
  --tab-baseline-default-display: none;
  --panel-header-bg-color: var(--light-grey);
  --panel-border-color: var(--light-grey);
  --side-panel-hover-color: var(--panel-border-color);
  --task-link-bg-color--active: var(--panel-border-color);
  --task-link-bg-color--hover: var(--very-light-grey);
  --input-color: var(--white);
  --input-border: #C3CCD1;
  --input-border-hover: #5C7889;
  --input-hidden-password-bg-color: #f9f9f9;
  --form-item-max-width: 650px;
  --form-item-max-width--small: 300px;
  --form-item-max-width--medium: 450px;
  --form-label-font-weight: bold;
  --form-input-padding: 8px;
  --form-input-border-radius: 6px;
  --form-input-glow: 0 0 0 10px transparent;
  --form-input-glow--focus: 0 0 0 5px var(--focus-input-glow);
  --standard-transition: 0.2s ease;
  --menu-text-color: black;
  --menu-bg-color: var(--white);
  --menu-selected-color: #b3d4ff;
  --menu-box-shadow: 0 3px 10px #bbb;
  --manage-option-bg-color--hover: var(--very-light-grey);
  --manage-option-bg-color--active: var(--light-grey);
  --manage-option-border-color: var(--medium-grey);
  --light-bg-color: #eee;
  --bright-bg-color: #f9f9f9;
  --brightest-bg-color: var(--white);
  --light-bg-color--hover: rgba(255, 255, 255, 0.65);
  --add-item-btn-decorator-border-color: #acb;
  --add-item-btn-decorator-bg-color: rgba(245, 249, 239, 0.75);
  --configure-job-bottom-sticker-bg-color: rgba(245, 249, 239, 0.75);
  --configure-job-bottom-sticker-border-color: var(--line-green);
  --plugin-manager-bg-color-already-upgraded: var(--light-grey);
  --plugin-manager-category-link-bg-color: var(--very-light-grey);
  --plugin-manager-category-link-bg-color--hover: #f2f2f2;
  --plugin-manager-category-link-border-color: var(--medium-grey);
  --plugin-manager-category-link-border-color--hover: var(--black);
  --plugin-manager-category-text-color: var(--text-color);
  --plugin-manager-category-link-color--hover: var(--text-color);
  --plugin-manager-unavailable-bg-color: #f4f4f4;
  --auto-complete-bg-color--prehighlight: #b3d4ff;
  --call-to-action-link-color: #000;
  --call-to-action-text-color: var(--alert-info-text-color);
  --green: #1c9146;
  --orange: #fe820a;
  --red: #df001c;
}
.ui-refresh .page-header {
  background-color: var(--header-bg-v2);
}
.ui-refresh #header .logo {
  display: none;
}
.ui-refresh a.page-header__brand-link {
  display: inline-flex;
  align-items: center;
  height: 100%;
  padding: 16px 24px;
  font-size: var(--font-size-base);
  font-weight: bold;
  background-color: var(--logo-bg);
  clip-path: polygon(0 0, 100% 0, 95% 100%, 0 100%);
}
.ui-refresh a.page-header__brand-link:link,
.ui-refresh a.page-header__brand-link:visited {
  color: var(--brand-link-color);
  text-decoration: none;
}
.ui-refresh a.page-header__brand-link:hover,
.ui-refresh a.page-header__brand-link:focus {
  text-decoration: underline;
  color: var(--brand-link-color-hover-v2);
  text-decoration-color: var(--brand-link-color-hover-v2);
}
.ui-refresh a.page-header__brand-link:link:focus {
  outline: none;
  color: var(--brand-link-color-active-v2);
  text-decoration-color: var(--brand-link-color-active-v2);
}
.ui-refresh a.page-header__brand-link:link:focus .page-header__brand-name {
  outline: auto;
}
.ui-refresh .page-header__hyperlinks a:hover,
.ui-refresh .page-header__hyperlinks a:focus,
.ui-refresh .page-header__hyperlinks a.mouseIsOverMenuSelector {
  background-color: var(--header-link-bg-hover-v2);
}
.ui-refresh .page-header__hyperlinks a:active {
  background-color: var(--header-link-bg-active-v2);
}
.ui-refresh .main-search__input:hover ~ .main-search__icon-leading {
  color: var(--header-link-bg-hover-v2);
}
.ui-refresh .main-search__input:focus ~ .main-search__icon-leading {
  color: var(--header-link-bg-active-v2);
}
.ui-refresh .main-search__icon-trailing:hover,
.ui-refresh .main-search__icon-trailing:focus {
  color: var(--header-link-bg-hover-v2);
}
.ui-refresh .main-search__icon-trailing:active {
  fill: var(--header-link-bg-active-v2);
}
.ui-refresh .am-container.visible .am-button {
  background-color: var(--header-link-bg-active-v2);
}
.ui-refresh .am-button .am-monitor__count {
  color: var(--monitor-color-v2);
  background-color: var(--monitor-bg-v2);
}
.ui-refresh .am-button.security-am .am-monitor__count {
  color: var(--sec-monitor-color-v2);
  background-color: var(--sec-monitor-bg-v2);
}
